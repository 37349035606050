.terms-and-conditions {
  margin-top: 24px;
  margin-bottom: 60px;
  h3 {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 8px;
  }
  h6 {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 24px;
  }
  > p {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
  }
  > h5 {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;
  }
  &__table-of-contents {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    row-gap: 16px;
    li {
      color: var(--primary-primary-blue, #1c89aa);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    > li {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      h5 {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      span {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 8px;
        border: 1px solid var(--gray-400, #a7a7a7);
        padding: 10px;
      }
      p {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      > ul {
        display: flex;
        flex-direction: column;
      }
    }
    .sub-ul {
      display: flex;
      flex-direction: column;
      list-style: auto;
      padding-left: 20px;
      li {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
