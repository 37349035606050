.create-poll {
  .MuiDialog-paperScrollPaper {
    width: 90%;
    max-width: 820px;
    h2 {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      padding: 20px 24px;
    }
    .MuiDialogActions-root .MuiButtonBase-root {
      padding: 12px 24px;
      border-radius: 8px;
      background: var(--primary-primary-blue, #1c89aa);
      color: var(--White-W100, #fff);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
