.reviews {
  margin-top: 24px;
  &__main__heading {
    margin-bottom: 53px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h3 {
      color: #333;
      text-align: center;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }

    &__search-field {
      margin-left: auto !important;
      max-width: 270px !important;
      padding: 8px 12px !important;
      border-radius: 8px !important;
      border: 1px solid #a7a7a7 !important;
      flex-basis: 1;
      .MuiInputBase-root.MuiOutlinedInput-root {
        height: 100%;
        padding: 0;
        input {
          padding: 0;
          color: var(--gray-450, #949494);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        fieldset {
          border: none;
          padding: 0;
        }
      }
    }
  }
  &__main-control {
    border-bottom: 1px solid #d3dfe2;
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    column-gap: 24px;
    button {
      border-radius: 0 !important;
      background: none !important;
      padding: 0 !important;
      padding-bottom: 10.5 !important;
      color: var(--gray-500, #636363) !important;
      font-family: Poppins !important;
      font-size: 18px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }
    button.active {
      font-weight: 500 !important;
      color: var(--primary-primary-blue, #1c89aa) !important;
      border-bottom: 3px solid #1c89aa !important;
    }
  }
  .reviews-metric {
    margin-bottom: 80px;
  }
  .reviews__container {
    h6 {
      color: var(--gray-500, #636363);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      .review-row {
        margin-bottom: 32px;
        border-bottom: 1px solid #d3dfe2;
        &:last-child {
          margin-bottom: 0;
          border: 0;
        }
      }
    }
  }
}
