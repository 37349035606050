#landing {
  .container {
    max-width: 95%;
    margin: 0 auto;
    position: relative;
  }
  .heading-container {
    padding: 0 2.5%;
    background: url("../../assets/landing-page__header-bg.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-color: var(--gray-50, #fcfcfc);
  }
  header {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 64px;
    h1 {
      color: var(--primary-primary-blue, #1c89aa);
      text-align: center;
      font-family: Comfortaa;
      font-size: 28.444px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.427px;
      strong {
        color: var(--secondery, #fa7518);
      }
    }
    .header__control {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      .MuiButton-root {
        width: fit-content;
        border-radius: 8px !important;
        padding: 12px 24px !important;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        height: 100%;
      }
      &__sign-in {
        background: #fff !important;
        background-color: #fff !important;
        color: var(--primary-primary-blue, #1c89aa) !important;
        border: 1px solid #1c89aa !important;
      }
      &__get-started {
        background: var(--primary-primary-blue, #1c89aa) !important;
        background-color: #1c89aa !important;
        color: var(--White-W100, #fff) !important;
      }
    }
  }
  .landing__hero {
    background: url("../../assets/landing-page__hero.png");
    background-repeat: no-repeat;
    background-position: center right;
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    padding-bottom: 164px;
    margin-bottom: 76px;

    h3 {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      text-transform: capitalize;
      background: url("../../assets/landing__hero__arc.png");
      background-repeat: no-repeat;
      background-position: bottom left;
      margin-bottom: 15px;
      padding-bottom: 20px;
      strong {
        color: var(--secondery, #fa7518);
        margin-right: 10px;
      }
    }
    p {
      color: var(--gray-500, #636363);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 40px;
    }
    &__actions {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 10px;
      .MuiButton-root {
        height: 52px !important;
        width: fit-content;
        border-radius: 8px !important;
        padding: 14px 24px !important;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        background: #1c89aa;
        background-color: #1c89aa;
        color: var(--primary-primary-blue, #fff);
      }
      &__rate {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        align-items: center;
        justify-content: center;
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  nav {
    background: #fff;
    z-index: 10;
    position: sticky;
    top: 0;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    &::after {
      content: "";
      position: absolute;
      width: 105%;
      height: 24px;
      bottom: -24px;
      z-index: 20;
      background: rgb(255 255 255 / 76%);
      filter: drop-shadow(8px 0px 3px #fff);
    }
    a {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 0 30px 12px;
      border-bottom: 1px solid #d3dfe2;
    }
    .active {
      color: var(--primary-primary-blue, #1c89aa);
      font-weight: 500;
      border-bottom: 3px solid #1c89aa;
    }
  }
  .container {
    section {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 115px;
      & > span {
        color: var(--secondery, #fa7518);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        position: relative;
        width: fit-content;
        display: block;
        margin-bottom: 24px;
        &::after {
          content: "";
          position: absolute;
          background: #fa7518;
          width: 136px;
          height: 1px;
          left: calc(100% + 24px);
          top: 50%;
          transform: translateY(-50%);
        }
      }
      img {
        height: fit-content;
        max-width: 100%;
        display: block;
      }
      .section-details {
        & > span {
          color: var(--secondery, #fa7518);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          position: relative;
          width: fit-content;
          display: block;
          margin-bottom: 24px;
          &::after {
            content: "";
            position: absolute;
            background: #fa7518;
            width: 136px;
            height: 1px;
            left: calc(100% + 24px);
            top: 50%;
            transform: translateY(-50%);
          }
        }
        h3 {
          color: var(--primary-black, #393939);
          font-family: Poppins;
          font-size: 36px;
          font-style: normal;
          font-weight: 600;
          line-height: 160%; /* 57.6px */
          letter-spacing: 1.26px;
          text-transform: capitalize;
          margin-bottom: 32px;
          strong {
            color: var(--secondery, #fa7518);
          }
        }
        max-width: 45%;
        h4 {
          color: var(--primary-black, #333);
          font-family: Poppins;
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          text-transform: capitalize;
          margin-bottom: 40px;
          strong {
            color: var(--secondery, #fa7518);
          }
        }
        p {
          color: var(--primary-black, #333);
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 50px;
        }
        .MuiButton-root {
          height: 52px !important;
          width: fit-content;
          border-radius: 8px !important;
          padding: 20px !important;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          background: #1c89aa;
          background-color: #1c89aa;
          color: var(--primary-primary-blue, #fff);
        }
        ul {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          li {
            padding: 23px;
            border-radius: 16px;
            background: var(--White-W100, #fff);
            box-shadow: 0px 0.501px 44.702px 0px rgba(250, 117, 24, 0.08),
              0px 4px 357px 0px rgba(250, 117, 24, 0.15);
            display: flex;
            column-gap: 35px;
            i {
              width: 45px;
              height: 45px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #fa7518;
              border-radius: 50%;
            }
            span {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              font-family: Poppins;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: var(--primary-black, #333);
              strong {
                color: var(--secondery, #fa7518);
              }
            }
          }
        }
      }
    }
    .learn-more {
      background: url("../../assets/landing-page__learn-more.png");
      background-repeat: no-repeat;
      background-position: right bottom;
      border-radius: 8px;
      padding: 95px 72px;
      background-color: #fa7518;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__details {
        h5 {
          color: var(--White-W100, #fff);
          font-family: Poppins;
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 56px */
          text-transform: capitalize;
          margin-bottom: 24px;
        }
        p {
          color: var(--White-W100, #fff);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 16px;
        }
        .users-imgs {
          display: flex;
          column-gap: 5px;
          align-items: center;
          img {
            max-height: 38px;
          }

          strong {
            color: var(--White-W100, #fff);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px; /* 228.571% */
            letter-spacing: 0.3px;
            text-decoration-line: underline;
          }
        }
      }
      .MuiButton-root {
        height: 60px !important;
        width: fit-content;
        border-radius: 4px !important;
        padding: 18px !important;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        background: #fff;
        background-color: #fff;
        color: #fb9c46;
      }
    }
  }
}
