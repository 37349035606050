.create-session {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-bottom: 52px;
  h2 {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .MuiFormControl-root {
    max-width: 550px !important;
    label {
      top: -5px !important;
    }
    .MuiInputBase-root {
      padding: 12px 39px 12px 8px !important;
    }
    input {
      padding: 0 !important;
    }
    fieldset {
      border-radius: 8px;
      border: 1px solid var(--gray-400, #a7a7a7);
    }
  }
  &__field--date.MuiFormControl-root .MuiInputBase-root {
    padding: 12px 12px 12px 8px !important;
  }
  &__field--files {
    h6 {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 6px;
    }
    p {
      color: var(--gray-500, #636363);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 24px;
    }
  }
  .MuiButton-root {
    width: fit-content;
    border-radius: 8px !important;
    background: var(--primary-primary-blue, #1c89aa) !important;
    background-color: #1c89aa !important;
    padding: 12px 24px !important;
    color: var(--White-W100, #fff) !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .date-time-label {
    display: flex;
    flex-direction: column;
    max-width: 550px;
    width: 100%;
    row-gap: 10px;
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    input {
      width: fit-content;
      padding: 8px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-bottom: 10px;
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
    span {
      color: #d32f2f;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.66;
      letter-spacing: 0.03333em;
      text-align: left;
      margin-top: 3px;
      margin-right: 14px;
      margin-bottom: 0;
      margin-left: 14px;
      position: absolute;
      bottom: -12px;
    }
  }
  .date-time-label.error {
    input {
      color: #d32f2f;
      border: 1px solid #d32f2f;
    }
    color: #d32f2f;
  }
}
