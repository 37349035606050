.classbuddy-navigation-bar {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;
  padding: 15px 60px;
  background: #fff;
  h1 {
    color: #1c89aa;
    text-align: center;
    font-family: Comfortaa;
    font-size: 28.444px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.427px;
    span {
      color: #fa7518;
    }
    margin-right: 110px;
  }
  &__search-field {
    flex-grow: 1;
    max-width: 710px;
    display: flex;
    padding: 12px 8px;
    border-radius: 8px;
    border: 1px solid #a7a7a7;

    .MuiTextField-root {
      flex-grow: 1;
      height: 100%;
      padding-right: 8px;
      border-right: 1px solid #a7a7a7;
    }
    .MuiInputBase-root {
      padding: 0;
      input {
        padding: 0;
      }
      .MuiSelect-select {
        width: 83px;
        padding: 0;
        padding-right: 32px;
        padding-left: 10px;
      }
      fieldset {
        padding: 0;
        border: none;
      }
    }
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__credit {
    margin-right: 16px !important;
  }
  &__notification {
    margin-right: 16px !important;
  }
}
