.charge-balance {
  .MuiDialogTitle-root {
    color: var(--Secondary-Dark-Color, #54595e);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .MuiButton-root {
    padding: 14px 20px;
    color: var(--Dark-Medium, #4f4f4f);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid var(--Dark-Medium, #4f4f4f);
    background: var(--White, #fff);
    flex-grow: 1;
    &[type="submit"] {
      background: var(--Dark-Medium, #4f4f4f);
      color: var(--Grey-Light, #f5f5f5);
    }
  }
  .MuiFormGroup-root {
    margin-bottom: 20px;
  }
}
