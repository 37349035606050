.review-row {
  display: flex;
  align-items: center;
  padding-bottom: 32px;
  .review-feedback {
    padding-right: 32px;
    border-right: 1px solid #d3dfe2;
    flex-grow: 1;
  }
  &__session-card {
    padding-left: 24px;
    h6 {
      color: var(--gray-500, #636363);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 6px;
    }
    figure {
      width: 364px;
      display: flex;
      padding: 12px;
      flex-direction: column;
      gap: 10px;
      border-radius: 16px;
      background: var(--White-W100, #fff);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    }
    &__figure-row {
      display: flex;
      column-gap: 10px;
    }
    &__img {
      width: 92px;
      height: 76px;
      border-radius: 8px;
      object-fit: contain;
    }
    figcaption {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
    &__title {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      justify-content: space-between;
      strong {
        display: flex;
        column-gap: 4px;
        color: var(--gray-500, #636363);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    &__description {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &__details {
      display: flex;
      justify-content: space-between;
      &__price {
        display: block;
        padding: 5px 19px;
        border-radius: 8px;
        background: rgba(250, 117, 24, 0.1);
        text-align: center;
        color: var(--secondery, #fa7518);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &__more-btn {
        display: flex;
        column-gap: 4px;
        border-radius: 8px !important;
        background: var(--primary-primary-blue, #1c89aa) !important;
        color: var(--White-W100, #fff) !important;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 186px;
        padding: 10px !important;
        svg path {
          stroke: #fff;
        }
      }
    }
  }
}
