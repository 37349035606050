// #hero {
//   margin-left: 0;
//   margin-right: 0;
//   background-size: "cover";
//   height: 565px;
//   justify-content: center;
//   background-position: "center";
//   text-align: center;
//   align-items: "center";
//   .hero-container {
//     display: inline-flex;
//     flex-direction: column;
//     align-items: center;
//     gap: 48px;
//     text-align: center;
//     h3 {
//       color: var(--primary-black, #333);
//       text-align: center;
//       font-family: Poppins;
//       font-size: 48px;
//       font-style: normal;
//       font-weight: 600;
//       line-height: 65px;
//       text-transform: capitalize;
//       margin-top: 170px;
//     }

//     span {
//       color: var(--secondery, #fa7518);
//       font-family: Poppins;
//       font-size: 48px;
//       font-style: normal;
//       font-weight: 600;
//       line-height: 65px;
//       text-transform: capitalize;
//     }

//     .explore-btn {
//       width: 279px;
//       padding: 12px 20px;
//       border-radius: 8px;
//       background: var(--primary-primary-blue, #1c89aa);
//       display: flex;
//       flex: 1 0 0;
//       text-transform: capitalize;
//       color: #fff;
//       text-align: center;
//       font-family: Poppins;
//       font-size: 16px;
//       font-style: normal;
//       font-weight: 500;
//       line-height: normal;
//     }
//   }
// }
#hero {
  padding-top: 65px;
  background-image: url("../../../assets/home-hero-bg.png");
  background-repeat: no-repeat;
  background-position: 95% 10%;
  .hero__user-greeting {
    color: #333;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
    strong {
      color: #fa7518;
    }
  }
  blockquote {
    color: #333;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    max-width: 620px;
    margin-bottom: 90px;
  }
  .hero__user-info {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 42px;
    padding: 30px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    div {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      color: #333;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      strong {
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.3px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        span {
          color: #1c89aa;
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}
