.packaging {
  margin-bottom: 40px;
  header {
    h3 {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 32px;
    }
    .header__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 48px;
      h4 {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        p {
          color: var(--gray-500, #636363);
          font-size: 14px;
        }
      }
    }
  }
  &__packages {
    display: flex;
    justify-content: space-between;
  }
}
