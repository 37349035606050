.review-feedback {
  &__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    &__user-details {
      display: flex;
      column-gap: 10px;
      figcaption {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        span {
          color: var(--primary-black, #333);
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          strong {
            font-weight: 400;
          }
        }
      }
    }
    &__date {
      color: var(--gray-500, #636363);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__content {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
  }
  &__report-btn {
    background-color: none !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    color: var(--gray-500, #636363) !important;
    /* regular/16 */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: underline !important;
    margin: 0 0 0 auto !important;
    display: block !important;
  }
}
