.credit-control {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 60px;
  h3 {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 32px;
  }
  &__credit-overview {
    width: 100%;
    display: flex;
    padding: 40px 70px;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    background: var(--gray-50, #fcfcfc);
    box-shadow: 0px 4px 10px 0px rgba(51, 51, 51, 0.2);
    margin-bottom: 60px;
    li {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 12px;
      span strong {
        color: var(--primary-primary-blue, #1c89aa);
        font-weight: 600;
      }
    }
  }
  &__credit-info {
    h4 {
      margin-bottom: 18px;
    }
    li {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 157%;
      list-style: inside;
    }
  }
  .box {
    h4 {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    padding: 25px;
    border-radius: 16px;
    background: var(--White-W100, #fff);
    box-shadow: 0px 4px 10px 0px rgba(51, 51, 51, 0.2);
  }
  .large {
    // width: calc(66% - 16px);
    width: 100%;
  }
  .small {
    // width: calc(33% - 16px);
    width: 100%;
  }
}
