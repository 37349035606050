.session-details {
  position: relative;
  .session-preview-card {
    position: fixed;
    top: 120px;
    right: 2.5%;
  }
  &__hero {
    margin: 0 -3% 48px;
    background: var(--gray-BG, #f3f3f3);
    padding: 60px 24px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .session-date {
      color: var(--secondery, #fa7518);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 2px 10px;
      border-radius: 50px;
      background: rgba(250, 117, 24, 0.15);
      width: fit-content;
    }
    .session-title {
      max-width: 510px;
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      text-transform: capitalize;
    }
    .session-summary {
      max-width: 600px;
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .session-tutor {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      a {
        color: var(--primary-primary-blue, #1c89aa);
        text-decoration-line: underline;
      }
    }
  }
  &__overview,
  &__topics,
  &__tutor,
  &__tutor-more-content {
    max-width: 800px;
    margin-bottom: 48px;
  }
  &__overview {
    padding-bottom: 40px;
    border-bottom: 1px solid #d3dfe2;

    h4 {
      color: var(--primary-primary-blue, #1c89aa);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 16px;
      text-transform: uppercase;
    }
    .session-title {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 16px;
    }
    .session-description {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__topics {
    h4 {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 32px;
    }
    .topics {
      padding: 24px 16px;
      border-radius: 16px;
      border: 1px solid var(--gray-400, #a7a7a7);
      background: var(--gray-BG, #f3f3f3);
      padding-bottom: 40px;
      border-bottom: 1px solid #d3dfe2;
      .session-title {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 16px;
      }
      li {
        list-style: disc;
        list-style-position: inside;
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 14px;
      }
      li:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__tutor {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding-bottom: 40px;
    border-bottom: 1px solid #d3dfe2;

    h4 {
      color: var(--primary-primary-blue, #1c89aa);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    h5 {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    figure {
      display: flex;
      column-gap: 24px;
      .MuiAvatar-root {
        width: 160px;
        height: 160px;
        font-size: 48px;
      }
      figcaption {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        span {
          display: flex;
          justify-content: flex-start;
          column-gap: 5px;
          // color: var(--primary-black, #333);
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .tutor-title {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .tutor-description {
      color: var(--gray-500, #636363);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .tutor-profile-link,
    .submit-review {
      padding-bottom: 40px;
      border-bottom: 1px solid #d3dfe2;
    }
  }
  &__tutor-more-content {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        strong {
          color: var(--primary-primary-blue, #1c89aa);
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .MuiButton-root {
        border-radius: 8px;
        border: 1px solid var(--primary-primary-blue, #1c89aa);
        color: var(--primary-primary-blue, #1c89aa);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 12px 20px;
      }
      margin-bottom: 40px;
    }
    .cards-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  &__right-content {
    padding: 12px 60px 40px;
    border-radius: 8px;
    background: var(--gray-BG, #f3f3f3);
    margin: 0 -3%;
    figure {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      column-gap: 80px;
      figcaption {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        h5 {
          color: #000;
          font-family: Poppins;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
        }
        p {
          color: #000;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 150% */
          letter-spacing: -0.3px;
        }
        .MuiButton-root {
          padding: 12px 24px;
          border-radius: 8px;
          background: var(--primary-primary-blue, #1c89aa);
          color: var(--White-W100, #fff);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: fit-content;
        }
      }
    }
  }
}
