.credit-control__history {
  h4 {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
  }
  .MuiDataGrid-root {
    height: fit-content;
  }
  .Fullfil {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: rgba(33, 150, 83, 0.2);
    color: var(--Green-1, #219653);
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 22.452px;
  }
  .Pending {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: rgba(242, 201, 76, 0.2);
    color: var(--Orange, #f2994a);
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 22.452px;
  }
  .true {
    color: var(--Green-1, #219653);
  }
  .false {
    color: var(--Red, #eb5757);
  }
}
