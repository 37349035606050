.session-card {
  //   max-width: 422px;
  max-width: 380px; // to be changed
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  &__img {
    width: 100%;
    max-height: 197px;
    border-radius: 8px;
    object-fit: cover;
  }
  &__fav-button {
    padding: 7px !important;
    position: absolute !important;
    top: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.7) !important;
  }
  &__caption {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      padding: 5px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 50px;
      color: #636363;
      background-color: #adadad;
    }
    &__title.G1 {
      background-color: #fa181826;
      color: #fa1818;
    }
    &__title.G2 {
      background-color: #fa751826;
      color: #fa7518;
    }
    &__title.G3 {
      background-color: #ffc10726;
      color: #ffc107;
    }
    &__time {
      color: #636363;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      strong {
        margin-left: 16px;
        font-weight: 400;
      }
    }
  }
  &__title {
    color: #333;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__subtitle {
    color: #333;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__description {
    color: #636363;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__details {
    display: flex;
    justify-content: space-between;
    &__students,
    &__price {
      display: flex;
      align-items: center;
      column-gap: 4px;
      color: #636363;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      strong {
        color: #fa7518;
      }
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    &__edit {
      display: flex !important;
      padding: 12px 20px !important;
      justify-content: center !important;
      align-items: center !important;
      gap: 5px !important;
      border-radius: 8px !important;
      border: 1px solid #1c89aa !important;
      color: #1c89aa !important;
      text-align: center !important;
      font-family: Poppins !important;
      //   font-size: 16px !important;
      font-size: 12px !important; // to be changed
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: normal !important;
    }
    &__view {
      display: flex !important;
      padding: 12px 20px !important;
      justify-content: center !important;
      align-items: center !important;
      gap: 5px !important;
      border-radius: 8px !important;
      background: #1c89aa !important;
      color: #fff !important;
      text-align: center !important;
      font-family: Poppins !important;
      //   font-size: 16px !important;
      font-size: 12px !important; // to be changed
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
      background: #fff;
      align-items: flex-start;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: normal !important;
    }
    .Mui-disabled {
      border: 1px solid #00000014 !important;
      color: #000 !important;
      background-color: #00000014 !important;
      svg {
        fill: #000;
        path {
          fill: #000;
          stroke: #000;
        }
      }
    }
    .fullwidth {
      width: 100%;
    }
  }
}
