.classBuddySlider {
  position: relative;
  .slick-list {
    padding: 10px 0;
  }
  .slick-next {
    color: #0000008a;
    &:before {
      content: unset;
    }
    &:hover,
    &:focus {
      color: #0000008a;
    }
  }

  .slick-next {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 15px !important;
    position: absolute;
    z-index: 100;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    &,
    &:hover {
      background-color: #fff;
      width: fit-content;
      height: fit-content;
    }
  }
}
