.contact-us {
  margin-top: 24px;
  margin-bottom: 65px;
  h3 {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 32px;
  }
  &__get-in-touch {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 8px;
      background: var(--secondery, #fa7518);
    }
    position: relative;
    padding: 32px;
    background: var(--gray-BG, #f3f3f3);
    margin-bottom: 24px;
    h4 {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }
    p {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      letter-spacing: -0.3px;
      margin-bottom: 24px;
    }
    &__contacts {
      display: flex;
      column-gap: 56px;
      div {
        display: flex;
        align-items: center;
        column-gap: 12px;
        color: var(--primary-primary-blue, #1c89aa);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: underline;
      }
    }
  }
  &__form {
    h4 {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }
    &__fields-wrapper {
      padding: 50px 100px;
      border-radius: 16px;
      background: var(--gray-50, #fcfcfc);
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      .row {
        display: flex;
        column-gap: 12px;
      }
      .MuiButton-root {
        width: fit-content;
        border-radius: 8px !important;
        background: var(--primary-primary-blue, #1c89aa) !important;
        background-color: #1c89aa !important;
        padding: 12px 24px !important;
        color: var(--White-W100, #fff) !important;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
