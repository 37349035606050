.credit-control__total-balance {
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .MuiAutocomplete-root {
      min-width: 160px;
      input {
        color: var(--gray-450, #949494) !important;
        font-family: Poppins !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
      }
    }
  }
}
