.CBCallendar {
  width: 100%;
  .rbc-toolbar {
    .rbc-toolbar-label {
      text-align: left;
      color: #636363;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .rbc-month-view {
    min-height: 950px;
    border-radius: 5px;
    border: 1px solid #d3dfe2;
    .rbc-row.rbc-month-header {
      .rbc-header {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #d3dfe2;
        & + .rbc-header {
          border-left: 1px solid #d3dfe2;
        }
        span {
          color: #636363;
          font-family: "Inter" "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
    .rbc-month-row {
      .rbc-row-bg {
        .rbc-day-bg {
          & + .rbc-day-bg {
            border-left: 1px solid #d3dfe2;
          }
        }
        .rbc-today {
          background-color: unset;
        }
      }
      .rbc-row-content {
        .rbc-row {
          .rbc-date-cell {
            padding: 10px 0 0 10px;
            text-align: left;
            .rbc-button-link {
              color: #636363;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
          .rbc-now.rbc-date-cell {
            .rbc-button-link {
              width: 30px;
              height: 30px;
              align-items: center;
              display: flex;
              justify-content: center;
              background-color: #1c89aa;
              border-radius: 50%;
              color: #fff;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
    }
    .rbc-event {
      background-color: unset !important;
      border: none !important;
      outline: none !important;
      .event {
        display: flex;
        width: 100%;
        padding: 2px 6px;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        flex-shrink: 0;
        border-radius: 4px;
        &.session {
          background-color: #5272e9;
        }
        &__title {
          color: #fff;
        }
        &__date {
          color: #fff;
          display: flex;
          column-gap: 5px;
          align-items: center;
          svg {
            width: 12px;
            height: 12px;
          }
          &__text {
            max-width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
  .custom-toolbar {
    margin-bottom: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__navigation {
      width: fit-content;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 20px;
      &__today,
      &__today:hover {
        border-radius: 8px;
        border: 1px solid #636363;
        color: #333;
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 12px 25px;
      }
      &__btn-group {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        button {
          width: fit-content;
          height: fit-content;
          svg path {
            fill: #1c89aa;
          }
        }
        .Mui-disabled {
          svg path {
            fill: #e6e6e6 !important;
          }
        }
      }
      &__label {
        color: #636363;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    &__add {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      border-radius: 8px;
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      height: 50px;
      width: 170px;
    }
  }
  // Add this to your SCSS
  .hidden-event {
    display: none !important; // Use !important to override any inline styles
  }

  .more-events-indicator {
    color: #fff;
    background-color: #1c89aa;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 25px;
    width: 100%;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      right: -10px;
      height: 100%;
      border: 10px solid #fff;
      border-left: 10px solid transparent;
    }
  }
}
