.submit-review {
  display: flex;
  column-gap: 6px;
  flex-direction: column;
  align-items: center;
  color: var(--gray-500, #636363);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
  width: 100%;
  .show-all-reviews-btn {
    color: var(--primary-primary-blue, #1c89aa);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    column-gap: 5px;
    background: none !important;
    border: none !important;
    width: fit-content;
  }
  h4 {
    color: var(--primary-primary-blue, #1c89aa);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
    text-transform: capitalize;
  }
  &__rate {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
    margin-bottom: 16px;
    span {
      // color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .MuiTextField-root {
    width: 100%;
    margin-bottom: 10px;
  }
  .MuiButtonBase-root.send-review {
    border-radius: 8px;
    background: var(--primary-primary-blue, #1c89aa) !important;
    padding: 10px 20px;
    color: var(--White-W100, #fff);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: auto;
    margin-right: 0;
    display: block;
    margin-bottom: 40px;
  }
  .reviews-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 40px;
    .document-details-review {
      figure {
        display: flex;
        column-gap: 15px;
        margin-bottom: 20px;
        .MuiAvatar-root {
          width: 70px;
          height: 70px;
        }
        figcaption {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          width: 100%;
          span {
            display: flex;
            justify-content: space-between;
            // color: var(--gray-500, #636363);
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            strong {
              color: var(--primary-black, #333);
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
      }
      p {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
