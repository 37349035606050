.sessions-summary {
  &__heading {
    width: 100%;
    min-width: 250px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 23px;
    span {
      color: var(--primary-black, #333);
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    button {
      color: var(--primary-primary-blue, #1c89aa);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background: none !important;
      border: none !important;
      padding: 4px 8px !important;
    }
  }
  .sessions-list {
    h5 {
      color: var(--primary-primary-blue, #1c89aa);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    height: fit-content;
    max-height: 400px;
    overflow-y: auto;
    padding-right: 17px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    /* Styling the scrollbar */
    &::-webkit-scrollbar {
      width: 6px; /* Width of the scrollbar */
    }

    /* Styling the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-primary-blue, #1c89aa);
      border-radius: 5px; /* Rounded corners for the thumb */
    }

    /* Styling the scrollbar track */
    &::-webkit-scrollbar-track {
      background-color: var(--gray-border, #d3dfe2);
    }

    &__event {
      &__date {
        color: var(--gray-500, #636363);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: block;
        width: fit-content;
        margin-bottom: 20px;
      }
      &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        column-gap: 20px;
        position: relative;
        margin-bottom: 24px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &__time {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        &::before {
          content: "";
          height: 100%;
          width: 4px;
          border-radius: 100px;
          background: #ff7782;
          position: absolute;
          top: 0;
          left: 123px;
        }
      }
      &__description {
        color: var(--gray-500, #636363);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
      }
    }
  }
}
