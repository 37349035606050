.classbuddy-layout {
  display: flex;
  position: relative;
  header.classbuddy-navigation-bar {
    flex-grow: 1;
    position: fixed;
    z-index: 1100;
    top: 0px;
    left: auto;
    right: 0px;
  }
  aside.classbuddy-aside {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 272px;
    min-height: 100vh;
  }
  main {
    margin-top: 80px;
    max-width: 100%;
    overflow: hidden;
    width: calc(100% - 80px);
    position: relative;
    .container {
      max-width: 95%;
      margin: 0 auto;
    }
  }
  main.expanded {
    width: calc(100% - 272px) !important;
  }
}
