.session-fw-card {
  width: 100%;
  display: flex;
  column-gap: 16px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 16px;
  position: relative;
  &__img {
    max-width: 225px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  &__more-btn {
    position: absolute !important;
    top: 16px;
    right: 16px;
    padding: 12px 20px !important;
  }
  &__date {
    display: block;
    width: fit-content;
    color: var(--gray-500, #636363);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 80px;
    background: rgba(253, 8, 8, 0.1);
    padding: 5px 8px;
    margin-bottom: 10px;
  }
  &__title {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
  }
  &__subtitle {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
  }
  &__description {
    color: var(--gray-450, #949494);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
  }
  &__university {
    display: flex;
    column-gap: 8px;
    color: var(--gray-500, #636363);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
  }
  &__students {
    display: flex;
    column-gap: 8px;
    color: var(--gray-500, #636363);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__price {
    display: flex;
    column-gap: 8px;
    color: var(--gray-500, #636363);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    strong {
      color: var(--secondery, #fa7518);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__view-btn {
    position: absolute !important   ;
    bottom: 16px !important;
    right: 16px !important;
    border-radius: 8px !important;
    background: var(--primary-primary-blue, #1c89aa) !important;
    padding: 12px 20px !important;
    color: #fff !important;
    text-align: center !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }
}
