.document-card {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(25% - 20px);
  min-width: 298px;
  padding: 16px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px #00000033;
  position: relative;
  &__fav-button {
    padding: 7px !important;
    position: absolute !important;
    top: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.7) !important;
  }
  &__img {
    width: 100%;
    max-height: 197px;
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 12px;
  }
  &__caption {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__date-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7.5px;
    &__date {
      color: #636363;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &__file-type {
      color: #fa7518;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 2px 10px;
      border-radius: 50px;
      background: rgba(250, 117, 24, 0.2);
    }
  }

  &__title {
    color: #333;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__subtitle {
    color: #333;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__description {
    color: #636363;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__mentor {
    display: flex;
    justify-content: space-between;
    color: #333;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    strong {
      color: #fa7518;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    margin-right: 8px;
    &__rate {
      color: #636363;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #d3dfe2;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    &__price {
      padding: 4px 10px;
      min-width: 92px;
      text-align: center;
      border-radius: 8px;
      background: rgba(250, 117, 24, 0.1);
      color: #fa7518;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    button {
      display: flex !important;
      padding: 12px 20px !important;
      justify-content: center !important;
      align-items: center !important;
      gap: 5px !important;
      border-radius: 8px !important;
      background: #1c89aa !important;
      color: #fff !important;
      text-align: center !important;
      font-family: Poppins !important;
      //   font-size: 16px !important;
      font-size: 12px !important; // to be changed
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
      background: #fff;
      align-items: flex-start;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: normal !important;
      svg path {
        fill: #fff;
        stroke: #fff;
      }
    }
  }
}
