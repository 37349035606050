.polls {
  margin-top: 24px;

  &__heading {
    margin-bottom: 24px;
    &__breadcrumb {
      margin-bottom: 24px;
      display: flex;
      justify-content: flex-start;
      column-gap: 2px;
      span {
        color: var(--gray-500, #636363);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    h3 {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 12px;
    }
    &__main__quote {
      color: var(--gray-500, #636363);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 24px;
    }
    &__main {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .MuiButtonBase-root.MuiButton-root {
        padding: 20px 12px !important;
        color: var(--White-W100, #fff) !important;
        border-radius: 8px !important;
        display: flex;
        column-gap: 8px;
        background: var(--primary-primary-blue, #1c89aa) !important;
        height: fit-content;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  &__control {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #d3dfe2;
    &__filter {
      border-radius: 8px !important;
      border: 1px solid var(--gray-450, #949494) !important;
      margin-left: auto;
      margin-right: 0;
      .MuiSelect-select {
        padding: 10px 50px 10px 20px !important;
        color: var(--primary-black, #333) !important;
        text-align: center !important;
        font-family: Poppins !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
      }
      fieldset {
        padding: 0;
        border: none;
      }
    }
    &__search-field {
      width: 450px !important;
      padding: 8px 12px !important;
      border-radius: 8px !important;
      border: 1px solid #a7a7a7 !important;
      flex-basis: 1;
      .MuiInputBase-root.MuiOutlinedInput-root {
        height: 100%;
        padding: 0;
        input {
          padding: 0;
          color: var(--gray-450, #949494);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        fieldset {
          border: none;
          padding: 0;
        }
      }
    }
  }
  &__content {
    margin-bottom: 80px;
    h3 {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }
    &__poll-cards-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    }
  }
}
