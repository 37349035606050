.swiper-container {
  list-style: none;
}

.pagination {
  bottom: 0;
  padding-bottom: 4px;
}

.slide {
  text-align: center;
  height: 320px;
  line-height: 320px;
  background: #364d79;
  overflow: hidden;
}

section {
  margin-bottom: 80px;
  .container {
    max-width: 95%;
    margin: 0 auto;
  }

  .section-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    h3 {
      color: #333;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
    p {
      color: #333;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.3px;
    }
    button {
      padding: 12px 20px;
      display: flex;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid #1c89aa;
      color: #1c89aa;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.slick-slide {
  // margin-right: 24px;
}
