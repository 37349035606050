.profile {
  .overview-tab {
    &__main {
      display: flex;
      justify-content: space-between;
      column-gap: 15%;
      &__info {
        p {
          color: #000;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 24px;
          max-width: 660px;
        }
        &__share {
          display: flex;
          justify-content: flex-start;
          column-gap: 10px;
          margin-bottom: 56px;
          button {
            width: 48px;
            height: 48px;
            background: rgba(211, 223, 226, 0.3) !important;
          }
        }
      }
      &__session {
        margin-bottom: 88px;
        h3 {
          color: var(--primary-black, #333);
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 36px;
        }
        &__card {
          max-width: 685px;
          flex-grow: 1;
          border-radius: 16px;
          background: var(--White-W100, #fff);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
          padding: 16px;
          .img-wrapper {
            width: 100%;
            height: 200px;
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 21px;
            img {
              height: 100%;
              width: auto;
              object-fit: cover;
            }
          }
          figcaption {
            position: relative;
            h4 {
              color: var(--primary-black, #333);
              font-family: Poppins;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 4px;
              max-width: 55%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            h5 {
              color: var(--primary-black, #333);
              font-family: Poppins;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            p {
              color: var(--gray-500, #636363);
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              max-width: 100%;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              margin-bottom: 18px;
            }
            h6 {
              color: var(--primary-black, #333);
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              display: flex;
              column-gap: 3px;
              align-items: center;
              padding-bottom: 16px;
              border-bottom: 1px solid #d3dfe2;
              margin-bottom: 16px;
              strong {
                color: var(--secondery, #fa7518);
                font-weight: 500;
              }
              svg {
                width: 20px;
                height: 20px;
              }
            }
          }
          &__control {
            display: flex;
            justify-content: space-between;
            span {
              padding: 6px 20px;
              border-radius: 8px;
              background: rgba(250, 117, 24, 0.1);
              color: var(--secondery, #fa7518);
              font-family: Poppins;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
            button {
              border-radius: 8px;
              background: var(--primary-primary-blue, #1c89aa);
              max-width: 229px;
              width: 100%;
              padding: 10px 0;
              color: var(--White-W100, #fff);
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              svg {
                margin-left: 4px;
                path {
                  fill: #fff;
                  stroke: #fff;
                }
              }
            }
          }
          &__date {
            border-radius: 50px;
            background: rgba(250, 117, 24, 0.15);
            color: var(--secondery, #fa7518);
            padding: 5px 10px;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
      &__contributors {
        h3 {
          color: var(--primary-black, #333);
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 32px;
        }
        &__cards-wrapper {
          display: flex;
          column-gap: 24px;
          .mentor-card {
            max-width: unset !important;
            min-width: 350px;
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: calc(30% - 24px);
          }
        }
      }
    }
    &__video-intro {
      max-width: 434px;
      width: fit-content;
      flex-shrink: 0;
      border-radius: 16px;
      background: var(--White-W100, #fff);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
      padding: 24px;
      height: fit-content;

      h3 {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 24px;
      }
      .img-wrapper {
        width: 100%;
        height: 225px;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 24px;
        img {
          height: 100%;
          width: auto;
          object-fit: cover;
        }
      }
      figcaption {
        h4 {
          color: var(--primary-black, #333);
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 20px;
        }
        .statistics-wrapper {
          display: flex;
          column-gap: 50px;
          row-gap: 20px;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-bottom: 24px;
          border-bottom: 1px solid #d3dfe2;
          margin-bottom: 24px;
          .statistic {
            width: calc(50% - 25px);
            display: flex;
            column-gap: 8px;
            i {
              width: 39px;
              height: 39px;
              padding: 8px 7px 7px 8px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            span {
              color: var(--gray-450, #949494);
              font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              display: flex;
              flex-direction: column;
              strong {
                color: var(--primary-black, #333);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }
          .statistic:nth-child(1) i {
            background: rgba(28, 137, 170, 0.1);
          }
          .statistic:nth-child(2) i {
            background: rgba(250, 117, 24, 0.1);
          }
          .statistic:nth-child(3) i {
            background: rgba(255, 90, 95, 0.1);
          }
        }
        .impact {
          h4 {
            color: var(--primary-black, #333);
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          p {
            color: var(--gray-450, #949494);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 16px;
          }
          .topics {
            display: flex;
            gap: 9px;
            .MuiChip-root {
              border-radius: 8px;
              background: rgba(28, 137, 170, 0.1);
              padding: 8px 10px;
              color: var(--primary-primary-blue, #1c89aa);
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
