.reset-password {
  max-width: 600px;
  margin: 90px auto 0;
  h3 {
    color: var(--primary-black, #333);
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 20px;
    text-align: center;
  }

  .MuiTextField-root {
    margin-bottom: 20px;
  }

  .MuiButton-root {
    width: 100%;
    background: var(--primary-primary-blue, #1c89aa);
    border-radius: 8px !important;
    padding: 12px 24px !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .error-msg {
    color: #c63307;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  p a,
  span a {
    color: #1c89aa;
    &:hover {
      text-decoration: underline;
    }
  }
}
