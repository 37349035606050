.packaging__package {
  padding: 40px;
  border-radius: 24px;
  border: 1px solid var(--gray-border, #d3dfe2);
  background: var(--White-W100, #fff);
  box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
  max-width: min(30%, 600px);
  figure {
    display: flex;
    column-gap: 5px;
    align-items: center;
    margin-bottom: 18px;
    .icon-wrapper {
      width: 72px;
      height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ecebff;
      border-radius: 10px;
    }
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__description {
    color: var(--Neutral-600, #6f6c90);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 22px;
  }
  &__amount {
    margin-bottom: 18px;
    color: var(--gray-500, #636363);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    strong {
      margin-right: 5px;
      color: var(--primary-primary-blue, #1c89aa);
      font-size: 48px;
      font-weight: 600;
      line-height: 140%;
      text-transform: capitalize;
    }
  }
  &__benefits {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 36px;
    li:first-of-type {
      margin-top: 16px;
    }
    li {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: var(--Neutral-800, #170f49);
      font-size: 18px;
      font-weight: 400;
    }
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    color: #fff;
    width: 100%;
    border-radius: 96px;
    padding: 26px 0;
    background: var(--primary-primary-blue, #1c89aa);
    color: var(--Neutral-100, var(--White-W100, #fff));
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
  }
}

.packaging__package.popular {
  border: 1px solid var(--Neutral-300, #eff0f6);
  background: var(--primary-primary-blue, #1c89aa);
  position: relative;
  .popular-tag {
    position: absolute;
    top: 40px;
    right: 40px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    color: var(--Neutral-100, var(--White-W100, #fff));
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 20px;
    display: block;
  }
  figure {
    color: var(--Neutral-100, var(--White-W100, #fff));
  }
  .packaging__package__description {
    color: var(--Neutral-400, #d9dbe9) !important;
  }
  .packaging__package__amount {
    color: var(--Neutral-100, var(--White-W100, #fff));

    strong {
      color: var(--Neutral-100, var(--White-W100, #fff));
    }
  }
  .packaging__package__benefits {
    color: var(--Neutral-100, var(--White-W100, #fff));
    li {
      color: #fff;
    }
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    color: var(--primary-primary-blue, #1c89aa);
    background: var(--Neutral-100, #fff);
  }
}
