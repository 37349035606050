.session-preview-card {
  max-width: 450px;
  width: 100%;
  height: fit-content;
  border-radius: 16px;
  background: var(--White-W100, #fff);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  overflow: hidden;
  z-index: 500;
  .video-player {
    width: 100%;
    height: 225px;
    margin-bottom: 16px;
    border-radius: 8px;
    background: var(--gray-100, #f1f1f1);
    position: relative;
    video {
      width: 100%;
      height: 100%;
    }
    .MuiIconButton-root {
      position: absolute;
      bottom: 5%;
      right: 5%;
      //   bottom: 20%;
      //   right: 20%;
      //   transform: translate(-50%, -50%);
      background: #dbdbdb54;
      transition: all ease-in-out 0.3s;
      &:hover {
        background: #c4c4c4;
      }
    }
  }
  .session-price {
    width: fit-content;
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 30px;
  }
  .session-schedule {
    h5 {
      color: var(--primary-primary-blue, #1c89aa);
      text-align: center;
      font-family: Poppins;
      font-size: 16;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .session-date {
      color: var(--primary-black, #333);
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 20px;
    }
    .session-control {
      display: flex;
      justify-content: space-between;
      column-gap: 16px;
      .session-enroll {
        width: 100%;
        padding: 12px 20px;
        border-radius: 8px;
        background: var(--primary-primary-blue, #1c89aa);
        color: var(--White-W100, #fff);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
      .MuiIconButton-root {
        border-radius: 8px;
        border: 1px solid var(--primary-primary-blue, #1c89aa);
        padding: 12px 23px;
        width: 70px;
      }
    }
  }
}
