.document-details {
  display: flex;
  justify-content: space-between;
  &__info {
    padding-top: 30px;
    background-color: #fff;
    max-width: 45%;
    padding: 12px 40px 12px 20px;
    border-right: 1px solid #d3dfe2;
    &__general {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid #d3dfe2;

      .document-date {
        color: var(--gray-500, #636363);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .document-title {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .document-description {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 150% */
        letter-spacing: -0.3px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .document-rate-and-downloads {
        .document-rate {
          display: flex;
          column-gap: 5px;
          color: var(--secondery, #fa7518);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .document-download {
          color: var(--primary-black, #333);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      h4 {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .MuiButtonBase-root {
          background: none !important;
          border: none !important;
          color: var(--primary-primary-blue, #1c89aa);
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;
        }
      }
      .document-price {
        color: var(--secondery, #fa7518);
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 8px;
        background: rgba(250, 117, 24, 0.1);
        padding: 4px 10px;
        display: block;
        width: fit-content;
        margin-left: auto;
      }
    }
    &__tutor {
      h4 {
        text-transform: capitalize;
        color: var(--primary-primary-blue, #1c89aa);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 16px;
      }
      &__card {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid #d3dfe2;
        border-radius: 16px;
        background: var(--White-W100, #fff);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
        padding: 10px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        &__row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 21px;
          .MuiAvatar-root {
            width: 100px;
            height: 100px;
          }
          .tutor-info {
            span {
              display: flex;
              column-gap: 10px;
              color: var(--primary-black, #333);
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
        h6 {
          color: var(--primary-black, #333);
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          color: var(--gray-500, #636363);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .MuiButtonBase-root {
          width: fit-content;
          display: flex;
          column-gap: 8px;
          color: var(--primary-primary-blue, #1c89aa);
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      &__documents {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid #d3dfe2;

        h4 {
          color: var(--primary-black, #333);
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          .MuiButtonBase-root {
            color: var(--primary-primary-blue, #1c89aa);
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        .documents-column {
          display: flex;
          flex-direction: column;
          row-gap: 24px;
          .document-details-card {
            display: flex;
            flex-direction: column;
            column-gap: 8px;
            figure {
              display: flex;
              column-gap: 8px;
              .img-container {
                width: 82px;
                height: 82px;
                border-radius: 8px;
                overflow: hidden;
                img {
                  height: auto;
                  max-width: 100%;
                  object-fit: contain;
                }
              }
              figcaption {
                display: flex;
                flex-direction: column;
                row-gap: 6px;
                .MuiButtonBase-root {
                  color: var(--primary-primary-blue, #1c89aa);
                  width: fit-content;
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
                p {
                  color: var(--gray-500, #636363);
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                span {
                  color: var(--primary-black, #333);
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  strong {
                    color: var(--secondery, #fa7518);
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                  }
                }
              }
            }
            .document-details-info {
              display: flex;
              justify-content: space-between;
              &__reviews,
              &__rate {
                display: flex;
                column-gap: 6px;
                align-items: center;
                color: var(--gray-500, #636363);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
              &__price {
                color: var(--secondery, #fa7518);
                text-align: center;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border-radius: 8px;
                background: rgba(250, 117, 24, 0.1);
                padding: 4px 8px;
                min-width: 92px;
              }
            }
          }
        }
      }
    }
    &__reviews {
      margin-bottom: 40px;
      h4 {
        color: var(--primary-primary-blue, #1c89aa);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 16px;
        text-transform: capitalize;
      }
      &__rate {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 5px;
        margin-bottom: 16px;
        span {
          color: var(--primary-black, #333);
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      .MuiTextField-root {
        width: 100%;
        margin-bottom: 10px;
      }
      .MuiButtonBase-root.send-review {
        border-radius: 8px;
        background: var(--primary-primary-blue, #1c89aa);
        padding: 10px 20px;
        color: var(--White-W100, #fff);
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: auto;
        margin-right: 0;
        display: block;
        margin-bottom: 40px;
      }
      .reviews-column {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-bottom: 40px;
        .document-details-review {
          figure {
            display: flex;
            column-gap: 15px;
            margin-bottom: 20px;
            .MuiAvatar-root {
              width: 70px;
              height: 70px;
            }
            figcaption {
              display: flex;
              flex-direction: column;
              row-gap: 10px;
              width: 100%;
              span {
                display: flex;
                justify-content: space-between;
                color: var(--gray-500, #636363);
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                strong {
                  color: var(--primary-black, #333);
                  font-family: Poppins;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                }
              }
            }
          }
          p {
            color: var(--primary-black, #333);
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .MuiButtonBase-root {
        display: block;
        margin: 0 auto;
        color: var(--primary-primary-blue, #1c89aa);
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 12px 20px;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--primary-primary-blue, #1c89aa);
      }
    }
  }
  .pdf-wrapper {
    padding-top: 30px;
    width: 100%;
  }
}
main:has(.document-details) {
  background: var(--gray-BG, #f3f3f3);
}
