.poll-card {
  border-radius: 16px;
  background: var(--White-W100, #fff);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  padding: 16px;
  flex: 1 0 calc(33% - 20px);
  min-width: 285px;
  &__user {
    display: flex;
    column-gap: 16px;
    width: 100%;
    margin-bottom: 30px;
    .MuiAvatar-root {
      width: 50px !important;
      height: 50px !important;
    }
    figcaption {
      width: 100%;
      overflow: hidden;
      position: relative;
      h5,
      h6 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      h5 {
        max-width: 60%;
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      h6 {
        max-width: 90%;
        color: var(--gray-500, #636363);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      span {
        position: absolute;
        right: 0;
        top: 0;
        width: fit-content;
        color: var(--gray-500, #636363);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  &__statistics {
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 20px;
    &__circle {
      width: 80px;
      height: 80px;
      position: relative;
      border: 7px solid #f99963;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .MuiCircularProgress-root.MuiCircularProgress-determinate {
        width: 80px !important;
        height: 80px !important;
        display: flex;
      }
    }
    figcaption {
      height: fit-content;
      span {
        color: var(--gray-500, #636363);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        column-gap: 6px;
        align-items: center;
      }
    }
  }
  &__info {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
  }
  &__closingDate {
    display: flex;
    column-gap: 2px;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 6px;
    border-radius: 50px;
    background: rgba(250, 117, 24, 0.2);
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 18px;
    width: fit-content;
    strong {
      color: var(--secondery, #fa7518);
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__actions {
    padding-top: 16px;
    border-top: 1px solid #d3dfe2;
    display: flex;
    justify-content: space-between;
    button {
      padding: 2px 6px !important;
      border-radius: 4px;
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      column-gap: 6px;
      align-items: center;
    }
    button.active-agree {
      svg {
        fill: #fa7518;
        path {
          stroke: #fa7518;
        }
      }
    }
    button.active-disagree {
      svg {
        fill: #4ca1bb;
        path {
          stroke: #4ca1bb;
        }
      }
    }
  }
}
