@import "react-big-calendar/lib/sass/styles";
#calendar {
  padding-top: 40px;
  display: flex;
  column-gap: 19px;
  margin: 0 auto 40px;
  justify-content: space-between;
  width: 100%;
  .calendar-view {
    width: 100%;
    h2 {
      margin-bottom: 20px;
    }
  }
  .calendar-tools {
    width: 320px;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    border-left: 1px solid #d3dfe2;
    padding-left: 20px;
    .MuiPaper-root {
      outline: none;
      box-shadow: none;
      background: transparent;
    }

    &__month-view {
      padding-bottom: 16px;
      border-bottom: 1px solid #d3dfe2;
    }
    &__today-schedule {
      h5 {
        color: #333;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 17px;
      }
      &__events-wrapper {
        max-height: 350px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        &::-webkit-scrollbar {
          width: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #d3dfe2;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #1c89aa;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #11586d;
        }
      }
      &__event {
        border-radius: 4px;
        border-left: 4px solid;
        padding: 6px;
        h6 {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        span,
        summary {
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: block;
          margin-bottom: 8px;
        }
      }
      &__event.session {
        border-color: #5272e9;
        background: #5273e925;
        color: #5272e9cc;
      }
    }
  }
  .rbc-event-content {
    font-family: Poppins;
    font-style: normal;
    line-height: normal;

    strong,
    span,
    p {
      max-width: 100%;
      white-space: break-spaces;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    strong {
      color: #000 !important;
      font-size: 15px;
      font-weight: 700;
    }
    span {
      color: #1c89aa !important;
      font-size: 13px;
      font-weight: 300;
    }
    p {
      color: #1c89aa !important;
      font-size: 12px;
      font-weight: 300;
    }
  }
}
