.mentor-card {
  max-width: 350px;
  padding: 16px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  &__info {
    min-height: 180px;
  }
  &__fav-button {
    padding: 7px !important;
    position: absolute !important;
    top: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.7) !important;
    svg {
      fill: #1c89aa !important;
    }
  }
  &__top-rated {
    position: absolute;
    top: 25px;
    left: 25px;
    color: #333;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 4px 10px;
    align-items: center;
    border-radius: 8px;
    background: #fff;
  }
  &__rating {
    position: absolute;
    top: 305px;
    right: 20px;
    border-radius: 50px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    column-gap: 2px;
    color: #636363;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: rgba(255, 255, 255, 0.7) !important;
  }
  &__img {
    width: 100%;
    max-height: 328px;
    border-radius: 8px;
    object-fit: cover;
  }
  &__caption {
    display: flex;
    flex-direction: column;
  }
  &__title {
    color: #333;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__summary {
    color: #636363;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 45px;
  }
  &__faculty {
    display: flex;
    column-gap: 4px;
    align-items: flex-start;
    color: #636363;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
  }
  &__contributions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 3px;
      color: #636363;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__actions button {
    width: 100% !important;
    padding: 12px 20px !important;
    border-radius: 8px !important;
    background: #1c89aa !important;
    color: #fff !important;
    text-align: center !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
