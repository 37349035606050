.pdf-viewer {
  width: fit-content;
  margin: 0 auto;
  .document-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 595px;
    min-height: 900px;
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    margin-top: 40px;
    .preview-popup {
      position: absolute;
      width: 550px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 16px;
      background: var(--primary-primary-blue, #1c89aa);
      display: flex;
      flex-direction: column;
      padding: 32px;
      justify-content: center;
      align-items: center;
      row-gap: 40px;
      h5 {
        display: flex;
        align-items: center;
        column-gap: 10px;
        color: var(--White-W100, #fff);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      p {
        color: var(--White-W100, #fff);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .features {
        display: flex;
        justify-content: space-between;
        span {
          color: var(--White-W100, #fff);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          align-items: center;
          column-gap: 10px;
        }
      }
      .MuiButtonBase-root {
        border-radius: 8px;
        background: #fff;
        min-width: 233px;
        width: fit-content;
        padding: 12px 20px;
        overflow: hidden;
        color: var(--primary-primary-blue, #1c89aa);
        text-align: center;
        text-overflow: ellipsis;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  & > .MuiButtonBase-root.download-btn {
    margin-left: 0;
    margin-right: auto;
  }
  .MuiButtonBase-root.download-btn {
    border-radius: 8px;
    background: var(--secondery, #fa7518);
    padding: 0;
    color: var(--White-W100, #fff);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    a {
      padding: 12px 20px;
      width: 100%;
      height: 100%;
      margin: 0;
      display: flex;
      justify-content: space-between;
      column-gap: 8px;
      align-items: center;
    }
  }
  .navigation {
    display: flex;
    column-gap: 5px;
    .MuiButtonBase-root {
      background-color: #696969;
    }
    .MuiButtonBase-root.Mui-disabled {
      background-color: #c9c9c9;
    }
  }
  .pagination {
    display: flex;
    column-gap: 5px;
    color: var(--White-W100, #fff);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    input {
      text-align: center;
      border: 1px solid #fff;
      border-radius: 2px;
      background-color: transparent;
      color: var(--White-W100, #fff);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .react-pdf__Document {
    margin-top: 20px;
  }
  &__control {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    border-radius: 4px;
    padding: 8px 16px;
    background: #1c89aa;
  }
  .react-pdf__Page__textContent.textLayer {
    display: none;
  }
}
