.reviews-tab {
  .review-feedback {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #d3dfe2;
    &:last-child {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
