.profile {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 170px;
    width: 100%;
    z-index: -1;
    background: url("../../assets/profile__bg.png");
    background-repeat: no-repeat;
    background-size: 1700px 170px;
  }
  &__heading {
    padding-top: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 64px;

    figure {
      display: flex;
      column-gap: 6px;
      align-items: center;
      .MuiAvatar-root {
        width: 180px;
        height: 180px;
        font-size: 72px;
        font-family: Poppins;
        font-weight: 600;
        line-height: normal;
      }
      figcaption {
        h2 {
          text-align: left;
          color: var(--primary-black, #333);
          font-family: Poppins;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
        }
        h5 {
          color: var(--primary-black, #333);
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 125% */
          letter-spacing: -0.36px;
        }
      }
    }
    &__control {
      display: flex;
      justify-content: space-between;
      column-gap: 32px;

      &__follow {
        height: fit-content;
        padding: 20px 40px !important;
        border-radius: 8px !important;
        background: var(--primary-primary-blue, #1c89aa) !important;
        color: var(--White-W100, #fff) !important;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &__edit {
        padding: 15px 18px !important;
        border-radius: 8px !important;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }
  #tabsWrapper {
    .MuiTabs-root {
      border-bottom: 1px solid #d3dfe2;
      margin-bottom: 24px;
      .MuiTab-root {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .MuiTab-root.Mui-selected {
        color: var(--primary-primary-blue, #1c89aa);
        font-weight: 500;
      }
      .MuiTabs-indicator {
        color: var(--primary-primary-blue, #1c89aa);
        height: 3px !important;
      }
    }
    margin-bottom: 155px;
  }
}
