.cb-accordion.MuiAccordion-root {
  border-radius: 6px;
  border: 1px solid #a7a7a7;
  margin: 0 !important;
  .MuiAccordionSummary-content {
    color: #333;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .MuiAccordionDetails-root {
    color: #333;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
