.inner-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  .controls {
    min-width: 300px;
    flex: 0 1 20%;
    padding-top: 24px;
    .controls-container {
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 32px;
      justify-content: center;
      align-items: center;
      .profile-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: capitalize;
        row-gap: 16px;
        &__edit {
          background-color: #fff;
          border: 1px solid #d9d9d9;
          svg path {
            fill: #1c89aa;
          }
        }
        &__wrapper {
          width: 144px;
          height: 144px;
        }
      }
      .gpa {
        text-align: center;
        max-width: 200px;
        padding: 15px 20px;
        border-radius: 8px;
        border: 1px solid #a7a7a7;
        background: #fff;
      }
      .navigation {
        width: 100%;
        &__button {
          &--active {
            background-color: #1c89aa !important;
            span {
              color: #fff;
            }
          }
          span {
            font-family: Poppins !important;
            font-size: 18px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
          }
        }
      }
    }
  }
  .main-body {
    flex: 0 1 80%;
    border-left: 1px solid #d9d9d9;
    .heading {
      padding: 24px 0;
      text-align: center;
      border-bottom: 1px solid #d9d9d9;
    }
    .row {
      display: flex;
      column-gap: 30px;
      padding: 32px 24px;

      form {
        display: flex;
        flex-direction: column;
        row-gap: 50px;
        width: 100%;
        .group {
          display: flex;
          flex-direction: column;
          row-gap: 24px;
          span {
            color: rgb(206, 3, 3);
          }
        }
        label {
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        input {
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .profile-completion {
        text-align: center;
        height: fit-content;
        padding: 15px 20px;
        border-radius: 8px;
        border: 1px solid #a7a7a7;
        background: #fff;
        width: 400px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }
    }
  }
}
