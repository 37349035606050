.CB__search-field {
  margin-left: auto !important;
  max-width: 270px !important;
  padding: 8px 12px !important;
  border-radius: 8px !important;
  border: 1px solid #a7a7a7 !important;
  flex-basis: 1;
  .MuiInputBase-root.MuiOutlinedInput-root {
    height: 100%;
    padding: 0;
    input {
      padding: 0;
      color: var(--gray-450, #949494);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    fieldset {
      border: none;
      padding: 0;
    }
  }
}
