.get-more {
  h3 {
    color: #333;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 32px;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    margin: 0 auto;
    column-gap: 24px;
  }
  &__left-section {
    width: 100%;
    max-width: 473px;
    border-radius: 8px;
    border: 1px solid #d3dfe2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    img {
      max-width: 473px;
      width: 100%;
      height: 100%;
    }
    figcaption {
      display: flex;
      padding: 0px 20px 10px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;
      color: #636363;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      strong {
        color: #333;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;
      }
    }
    button {
      border-radius: 8px !important;
      background: #1c89aa !important;
      padding: 12px 20px !important;
      color: #fff !important;
      text-align: center !important;
      font-family: Poppins !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: normal !important;
      margin-right: auto;
    }
  }
  &__right-section {
    width: 100%;
    max-width: 563px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    align-self: stretch;
    &__top {
      display: flex;
      padding: 24px 11px 33px 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      border-radius: 8px;
      background: #1c89aa;
      &__bank {
        overflow: hidden;
        padding: 27.5px 25px;
        border-radius: 200px;
        background: #fff;
        gap: 10px;
        border-radius: 200px;
        background: #fff;
      }
      strong {
        color: #fff;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      p {
        color: #fff;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    figure {
      display: flex;
      flex-direction: row;
      column-gap: 16px;
      border-radius: 8px;
      border: 1px solid #d3dfe2;
      height: 336px;
      img {
        width: 100%;
        max-width: 306px;
      }
      figcaption {
        color: var(--primary-black, #333);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        button {
          display: flex;
          padding: 12px 33px 12px 25px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: var(--primary-primary-blue, #1c89aa) !important;
          color: #fff !important;
          text-align: center;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: fit-content;
          margin-right: auto;
        }
      }
    }
  }
}
