.monthly-calendar-day {
  text-align: center;
  position: relative;
  .day-number {
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 25px;
    height: 25px;
    display: block;
    line-height: 25px;
    overflow: hidden;
  }
  .day-number.has-event {
    color: #1c89aa;
    // border: 1px solid #1c89aa;
    // border-radius: 50%;
    font-weight: 700 !important;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // width: 20px;
    // height: 20px;
    // border-radius: 50%;
  }
  .day-number.is-today {
    color: #fff;
    border: 1px solid #1c89aa;
    background: #1c89aa;
    border-radius: 50%;
  }

  .day-number.off-month {
    color: #000;
    border: 1px solid #adadad;
    background: #adadad;
    border-radius: 50%;
  }
  .pushpin-icon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(75%, -50%);
    z-index: -1;
    width: 12px;
  }
}

.monthly-calendar-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #636363;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .Mui-disabled {
    svg path {
      stroke: #e6e6e6;
    }
  }
}
