.credit-control__credit-exchanger {
  h4 {
    margin-bottom: 35px;
  }
  & > span {
    color: #333;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    column-gap: 28px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
    strong {
      color: #ab9f9f;
    }
  }
  &__amount {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 7px 8px 17px;
    border-radius: 7px;
    background: var(--Cool-Gray-01, #f9f9fa);
    margin-bottom: 30px;
    .colored-label {
      border-radius: 7px;
      background: #c7e4db;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 75px;
      height: 42px;
      color: #219653;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .MuiFormControl-root {
      width: 95%;
    }
    input {
      color: var(--Cool-Gray-09, #626d7d);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 22.452px;
    }
    fieldset {
      border: none;
    }
    .MuiAutocomplete-root {
      width: 150px;
      border-radius: 7px;
      background: #fcf4db;
      color: #ff7c04;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &__convertBtn {
    border: none;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    background: var(--primary-primary-blue, #1c89aa);
    color: var(--White-W100, #fff);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
