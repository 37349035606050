.reviews-metric {
  margin-bottom: 80px;
  h4 {
    display: flex;
    column-gap: 4px;
    align-items: center;
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 32px;
  }
  .rate {
    margin-bottom: 20px;
    display: block;
    position: relative;
    padding-left: 432px;
    align-items: center;
    color: var(--primary-primary-blue, #1c89aa);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: -0.3px;
    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 400px;
      background-color: #a7a7a7;
      height: 16px;
      border-radius: 8px;
      z-index: 9;
    }
    .rate-percentage {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #1c89aa;
      height: 16px;
      border-radius: 8px;
      z-index: 10;
    }
    // &::after {
    //   position: absolute;
    //   content: "";
    //   left: 0;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   background-color: #1c89aa;
    //   height: 16px;
    //   border-radius: 8px;
    //   z-index: 10;
    // }
  }
  // .rate-5::after {
  //   width: 400px;
  // }
  // .rate-4::after {
  //   width: calc(4 * 400px / 5);
  // }
  // .rate-3::after {
  //   width: calc(3 * 400px / 5);
  // }
  // .rate-2::after {
  //   width: calc(2 * 400px / 5);
  // }
  // .rate-1::after {
  //   width: calc(1 * 400px / 5);
  // }
}
