@import "./assets/Style/main.scss";

/* Basic CSS Reset / Normalize */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}
