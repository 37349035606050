.classbuddy-aside.not-expanded {
  width: 80px !important;
  a {
    width: fit-content;
    margin: 0 auto;
  }
}
.classbuddy-aside {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  &__toggle-button {
    position: absolute !important;
    top: 5px;
    right: 0;
    border-radius: 4px !important;
    padding: 0px !important;
    border: 1px solid #d3dfe2 !important;
  }
  &__container {
    overflow-x: visible;
    padding: 30px 20px;
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    z-index: 1200;
    position: fixed;
    top: 79px;
    left: 0px;
    max-height: calc(100vh - 80px);
  }

  &__user-figure {
    display: flex;
    column-gap: 20px;
    padding-bottom: 24px;
    margin-bottom: 32px;
    figcaption {
      display: flex;
      flex-direction: column;
      strong {
        color: #333;
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      span {
        display: flex;
        flex-direction: row;
        color: #636363;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  &__main {
    padding-bottom: 24px;
    border-bottom: 1px solid #d3dfe2;
    margin-bottom: 24px;
    .MuiCollapse-root {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }
  &__main,
  &__sub {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    .MuiListItemButton-root {
      padding: 0;
      column-gap: 10px;
      svg {
        width: 30px;
        height: 30px;
        color: #929292;
      }
    }

    .MuiListItemIcon-root {
      min-width: unset !important;
    }
    a {
      display: flex;
      column-gap: 10px;
      align-items: center;
      svg {
        width: 30px;
        height: 30px;
        color: #929292;
        // path {
        //   fill: #929292;
        //   stroke: #929292;
        // }
      }
    }
    // a.active {
    //   color: #1c89aa;
    //   svg path {
    //     fill: #1c89aa;
    //   }
    // }
    // a:hover {
    //   color: #3392af;
    //   svg {
    //     path {
    //       stroke: #3392af;
    //     }
    //   }
    // }
  }
}
