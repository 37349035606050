.documents-tab {
  h3 {
    color: var(--primary-black, #333);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;
  }
  &__documents-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(33% - 12px), 1fr));
    gap: 24px;
    .document-card {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 100%;
      max-width: unset !important;
      width: auto;
    }
  }
}
