.my-teaching {
  display: flex;
  margin-top: 24px;
  &__empty-figure {
    display: flex;
    flex-direction: column;
    row-gap: 38px;
    width: fit-content;
    margin: 0 auto;
    margin-top: 91px;
    align-items: center;
    figcaption {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__main {
    flex-grow: 1;
    padding: 0 20px;
    border-right: 1px solid #d3dfe2;
    min-height: 100vh;
    &__heading {
      margin-bottom: 37px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      h3 {
        color: #333;
        text-align: center;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        padding-right: 16px;
        border-right: 1px solid #d3dfe2;
      }
      button {
        display: flex;
        column-gap: 4px;
        background-color: none !important;
        padding: 8px 16px !important;
        color: #636363;
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      button.active {
        color: #1c89aa;
        font-weight: 500 !important;
        svg {
          path {
            fill: #1c89aa;
            stroke: #1c89aa;
          }
        }
      }
      &__search-field {
        margin-left: auto !important;
        max-width: 270px !important;
        padding: 8px 12px !important;
        border-radius: 8px !important;
        border: 1px solid #a7a7a7 !important;
        flex-basis: 1;
        .MuiInputBase-root.MuiOutlinedInput-root {
          height: 100%;
          padding: 0;
          input {
            padding: 0;
            color: var(--gray-450, #949494);
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          fieldset {
            border: none;
            padding: 0;
          }
        }
      }
    }
    &__control {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #d3dfe2;
      margin-bottom: 24px;
      padding-bottom: 12px;
      &__sessions-control {
        width: fit-content;
        display: flex;
        justify-content: space-between;
        column-gap: 24px;
        button {
          border-radius: 0 !important;
          background: none !important;
          padding: 0 !important;
          padding-bottom: 10.5 !important;
          color: var(--gray-500, #636363) !important;
          font-family: Poppins !important;
          font-size: 18px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
        }
        button.active {
          font-weight: 500 !important;
          color: var(--primary-primary-blue, #1c89aa) !important;
          border-bottom: 3px solid #1c89aa !important;
        }
      }
      &__filter {
        border-radius: 8px !important;
        border: 1px solid var(--gray-450, #949494) !important;
        margin-left: auto;
        margin-right: 0;
        .MuiSelect-select {
          padding: 10px 50px 10px 20px !important;
          color: var(--primary-black, #333) !important;
          text-align: center !important;
          font-family: Poppins !important;
          font-size: 16px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
        }
        fieldset {
          padding: 0;
          border: none;
        }
      }
    }
    &__content {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
  &__side {
    margin-left: 30px;
    max-width: 280px;
  }
  &__add-new-btn {
    margin-bottom: 48px !important;
    padding: 12px 33px 12px 25px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    border-radius: 8px !important;
    background: var(--primary-primary-blue, #1c89aa) !important;
    box-shadow: 0px 4px 10px 0px rgba(28, 137, 170, 0.2) !important;
    color: #fff !important;
    text-align: center !important;
    font-family: Poppins !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    svg path {
      fill: #fff !important;
    }
  }
}
