.faq {
  margin-top: 24px;
  margin-bottom: 60px;
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;

    h3 {
      color: var(--primary-black, #333);
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
}
